<script setup>
import { storeToRefs } from 'pinia';
import { useUserStore } from '../scripts/stores/user';
import { useNavigationStore } from '../scripts/stores/navigation';
import { onBeforeMount, onMounted, ref } from 'vue';
import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { toast } from "bulma-toast"
import { handleSignOut } from '../scripts/utils/fbase';
import router from '../scripts/router';


const { isAccessDenied } = storeToRefs(useUserStore())
const { pathToRedirectAfterSignin } = storeToRefs(useNavigationStore())

function navigateAfterSignin() {
    const path = pathToRedirectAfterSignin.value || "/"
    router.push(path)
}

onBeforeMount(() => {
    if (getAuth().currentUser) navigateAfterSignin()
})

onMounted(() => {
    const form_signin = document.getElementById('form_signin')

    form_signin.addEventListener('submit', async (ev) => {
        ev.preventDefault()
        const form = ev.target

        const email = form.email.value
        const password = form.password.value

        const auth = getAuth()
        form.submit.classList.add('is-loading')

        let authStatePersistence = browserSessionPersistence
        if (form.keep_signed_in.checked) authStatePersistence = browserLocalPersistence

        setPersistence(auth, authStatePersistence)
            .then(() => signInWithEmailAndPassword(auth, email, password))
            .then(userCredential => {
                const user = userCredential.user
                console.info("Firebase: Existing user sign in.", user)
                console.info("Firebase: Existing user sign in.", user.uid)
                form.submit.classList.remove('is-loading')
                navigateAfterSignin()
            })
            .catch(error => {
                toast({
                    type: 'is-danger',
                    dismissible: true,
                    position: 'top-center',
                    message: 'Invalid credentials! Please, try again with correct details or try creating a new account.'
                })
                console.error("Firebase: Could not sign in.", error)
            })

        form.submit.classList.remove('is-loading')
    })


    try {
        const btn_signout_el = document.getElementById('btn_signout')
        btn_signout_el.addEventListener('click', () => {
            btn_signout_el.classList.add('is-loading')
            handleSignOut()
            btn_signout_el.classList.remove('is-loading')
        })
    } catch (error) {
        console.error("App: Could not add click event listner to #btn_signout.", error)
    }

})

</script>

<template>
    <header>
        <nav class="navbar is-fixed-top is-justify-content-center" role="navigation" aria-label="main navigation">
            <!-- add padding-top: 3.25rem ro body to bring content down = navbr's height  -->
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item">
                    <img src="../assets/logo-nearly-famous.png" width="512" height="128"
                        alt="Nearly Famous Enterprises Inc.">
                </router-link>
            </div>
        </nav>
    </header>
    <main>
        <section class="hero is-primary is-fullheight-with-navbar bakground-image-midyear is-hidden-signed-in">
            <div class="hero-body">
                <div class="box animate__zoomIn mx-auto" style="width: 100%; max-width: 30rem;">
                <h1 class="title has-text-centered has-text-dark">Sign in</h1>
                <form id="form_signin" action="/signin" method="post">
                    <div class="field">
                        <label class="label is-visually-hidden" for="email">Email</label>
                        <div class="control">
                            <input id="email" class="input" type="email" placeholder="Email: user@example.tld"
                                autocomplete="email" required>
                        </div>
                        <!-- <p class="help is-danger">This email is invalid</p> -->
                    </div>
                    <div class="field">
                        <label class="label is-visually-hidden" for="password">Password</label>
                        <div class="control">
                            <input id="password" class="input" type="password" placeholder="Enter your password" required>
                        </div>
                        <!-- <p class="help is-danger">This email is invalid</p> -->
                    </div>
                    <div class="field">
                        <label class="checkbox" for="keep_signed_in">
                            <input type="checkbox" id="keep_signed_in">
                            Keep me signed in.
                        </label>
                        <span class="has-tooltip-arrow has-tooltipl-multiline"
                            data-tooltip="Stay signed in even if you close your browser window.&#10;Make sure you are on a personal device.">details</span>
                    </div>
                    <button name="submit" class="button is-primary" type="submit">Sign in</button>
                    <!-- <div class="content mt-3">
                                <p class="has-text-link"><span class="is-clickable">Forgot your password?</span></p>
                            </div> -->
                </form>
            </div>
            </div>
        </section>
        <section class="hero is-primary is-fullheight-with-navbar bakground-image-midyear is-hidden-signed-out">
            <div class="hero-body">
                <div class="box animate__zoomIn mx-auto" style="width: 100%; max-width: 30rem;">
                <p v-if="isAccessDenied" class="is-size-3 has-text-centered">Access denied</p>
                <p v-else class="is-size-3 has-text-centered">Sign in successful.</p>
                <div class="buttons is-centered">
                    <router-link v-if="!isAccessDenied" to="/" class="button is-primary is-light">
                        Home
                    </router-link>
                    <button id="btn_signout" class="button is-light" type="button">
                        Sign out
                    </button>
                </div>
            </div>
            </div>
        </section>
    </main>
</template>