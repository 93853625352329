<script setup>
import { storeToRefs } from 'pinia';
import { useNavigationStore } from '../../scripts/stores/navigation';
import { handleSignOut } from "../../scripts/utils/fbase"

const { isNavMenuOpen } = storeToRefs(useNavigationStore())
const { toggleNavMenu, closeNavMenu } = useNavigationStore()

</script>

<template>
    <header>
        <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <!-- add padding-top: 3.25rem ro body to bring content down = navbr's height  -->
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item" @click="closeNavMenu()">
                    <img src="../../assets/logo-nearly-famous.png" width="512" height="128"
                        alt="Nearly Famous Enterprises Inc.">
                </router-link>

                <a role="button" class="navbar-burger" :class="{ 'is-active': isNavMenuOpen }" aria-label="menu"
                    aria-expanded="false" data-target="main_navbar" @click="toggleNavMenu()">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="main_navbar" class="navbar-menu" :class="{ 'is-active': isNavMenuOpen }" @click="closeNavMenu()">
                <div class="navbar-end">
                    <router-link to="/" class="navbar-item">Home</router-link>
                    <router-link to="/catalogues" class="navbar-item">Catalogues</router-link>
                    <router-link to="/products" class="navbar-item">Products</router-link>
                    <router-link to="/collections" class="navbar-item">Collections</router-link>

                    <div class="navbar-item has-dropdown is-hoverable">
                        <span class="navbar-link">
                            Admin Functions
                        </span>

                        <div class="navbar-dropdown is-right">
                            <router-link to="/upload-static-files" class="navbar-item">Upload Static Files</router-link>
                            <router-link to="/assign-customer-number" class="navbar-item">Assign Customer Number</router-link>
                            <router-link to="/change-user-password" class="navbar-item">Change User Password</router-link>
                            <hr class="navbar-divider">
                            <router-link to="/assign-user-role" class="navbar-item">Assign User Role</router-link>
                        </div>
                    </div>

                    <div class="navbar-item">
                        <div class="buttons">
                            <button id="btn_signout" class="button is-light is-hidden-signed-out" type="button"
                                @click="handleSignOut()">
                                Sign out
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </nav>
    </header>
</template>