<script setup>
import router from "../scripts/router"
</script>

<template>
    <header>
        <nav class="navbar is-fixed-top is-justify-content-center" role="navigation" aria-label="main navigation">
            <!-- add padding-top: 3.25rem ro body to bring content down = navbr's height  -->
            <div class="navbar-brand">
                <router-link to="/" class="navbar-item">
                    <img src="../assets/logo-nearly-famous.png" width="512" height="128"
                        alt="Nearly Famous Enterprises Inc.">
                </router-link>
            </div>
        </nav>
    </header>
    <main>
        <section class="section is-large is-primary is-fullheight-with-navbar">
            <div class="content has-text-centered" style="max-width: 30rem; margin-inline: auto;">
                <h1 class="title"><span style="font-size: 6rem;">404</span><br>Not Found</h1>
                <p><del>🔗</del>{{ router.currentRoute.value.fullPath }}<br>The requested URL was not found.</p>
                <router-link to="/" class="button is-dark">
                    Home
                </router-link>
            </div>
        </section>
    </main>
</template>