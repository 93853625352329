<script setup>
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { toast } from 'bulma-toast';
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { storeToRefs } from "pinia";
import { onMounted, ref, watch } from 'vue';
import { read, writeFileXLSX, utils as xlsxUtils } from 'xlsx';
import { useConfigStore } from '../scripts/stores/config';
import { formatterCurrency } from "../scripts/utils/helper-functions";

const { appList } = storeToRefs(useConfigStore())

const selectedApp = ref(process.env.FBASE_APP_ID)
const isProductsLoading = ref(false)
const isProductEditModalOpen = ref(false)
const isProductImportExportModalOpen = ref(false)
const productEditFormButtonLabel = ref('Add')
const products = ref({})
const import_files = ref([])

const productsImportExportArrayDelimiter = ';'
const productsImportExportHeaders = [
    "key",
    "sku",
    "barcode",
    "status",
    "name",
    "moq",
    "price",
    "retail",
    "qtyAvailable",
    "eta",
    "position",
    "specialText",
    "description",
    "additionalInfo",
    "images",
    "tags",
    "filters",
    "alternateKeys"
]

const productInfoDefaultValue = {
    key: '',
    sku: '',
    barcode: '',
    status: '',
    name: '',
    moq: 0,
    price: 0,
    retail: 0,
    qtyAvailable: '0',
    eta: '',
    position: 0,
    specialText: '',
    description: '',
    additionalInfo: '',
    images: '',
    tags: '',
    filters: '',
    alternateKeys: ''
}

const productInfo = ref({})
productInfo.value = productInfoDefaultValue

const s3Credentials = ref({})

onMounted(() => {
    refreshProducts()
    const functions = getFunctions()
    const getCfR2Keys = httpsCallable(functions, 'getCfR2Keys')

    getCfR2Keys()
        .then(response => {
            const cfR2Credentials = response.data
            if (cfR2Credentials.hasOwnProperty('accessKeyId') && cfR2Credentials.hasOwnProperty('secretAccessKey')) {
                s3Credentials.value = cfR2Credentials
            }
            else {
                console.log("Can not find R2 credentials.")
            }
        })
})

watch(selectedApp, () => {
    refreshProducts()
})

async function refreshProducts() {
    isProductsLoading.value = true
    products.value = {}
    // Use https://cache-bypassed.static.nearlyfamous.ca/ instead of https://static.nearlyfamous.ca/ to get the resource directly(uncached).
    const url = "https://cache-bypassed.static.nearlyfamous.ca/" + selectedApp.value + "/products.json"

    fetch(url, { cache: 'no-cache' })
        .then(res => res.json())
        .then(productsDataPublic => {
            if (typeof productsDataPublic === 'object' && !Array.isArray(productsDataPublic)) {
                products.value = Object.fromEntries(Object.entries(productsDataPublic).sort(([, a], [, b]) => b.position - a.position))
            }
            else products.value = {}
        })
        .then(() => {
            const docRef = doc(getFirestore(), selectedApp.value, "products")
            return getDoc(docRef)
        })
        .then(docSnapshot => {
            const data = docSnapshot.data()

            return JSON.parse(data.json)
        })
        .then(productDataProtected => {
            Object.keys(products.value).forEach(key => {
                if (productDataProtected.hasOwnProperty(key)) {
                    products.value[key].price = productDataProtected[key].price
                }
            })
            isProductsLoading.value = false
        })
        .then(() => {
            Object.keys(products.value).forEach(productKey => {
                if (!products.value[productKey].hasOwnProperty('key')) products.value[productKey]['key'] = productInfoDefaultValue.key
                if (!products.value[productKey].hasOwnProperty('sku')) products.value[productKey]['sku'] = productInfoDefaultValue.sku
                if (!products.value[productKey].hasOwnProperty('barcode')) products.value[productKey]['barcode'] = productInfoDefaultValue.barcode
                if (!products.value[productKey].hasOwnProperty('status')) products.value[productKey]['status'] = productInfoDefaultValue.status
                if (!products.value[productKey].hasOwnProperty('name')) products.value[productKey]['name'] = productInfoDefaultValue.name
                if (!products.value[productKey].hasOwnProperty('moq')) products.value[productKey]['moq'] = productInfoDefaultValue.moq
                if (!products.value[productKey].hasOwnProperty('price')) products.value[productKey]['price'] = productInfoDefaultValue.price
                if (!products.value[productKey].hasOwnProperty('retail')) products.value[productKey]['retail'] = productInfoDefaultValue.retail
                if (!products.value[productKey].hasOwnProperty('qtyAvailable')) products.value[productKey]['qtyAvailable'] = productInfoDefaultValue.qtyAvailable
                if (!products.value[productKey].hasOwnProperty('eta')) products.value[productKey]['eta'] = productInfoDefaultValue.eta
                if (!products.value[productKey].hasOwnProperty('position')) products.value[productKey]['position'] = productInfoDefaultValue.position
                if (!products.value[productKey].hasOwnProperty('specialText')) products.value[productKey]['specialText'] = productInfoDefaultValue.specialText
                if (!products.value[productKey].hasOwnProperty('description')) products.value[productKey]['description'] = productInfoDefaultValue.description
                if (!products.value[productKey].hasOwnProperty('additionalInfo')) products.value[productKey]['additionalInfo'] = productInfoDefaultValue.additionalInfo
                if (!products.value[productKey].hasOwnProperty('images')) products.value[productKey]['images'] = productInfoDefaultValue.images.split(productsImportExportArrayDelimiter)
                if (!products.value[productKey].hasOwnProperty('tags')) products.value[productKey]['tags'] = productInfoDefaultValue.tags.split(productsImportExportArrayDelimiter)
                if (!products.value[productKey].hasOwnProperty('filters')) products.value[productKey]['filters'] = productInfoDefaultValue.filters.split(productsImportExportArrayDelimiter)
                if (!products.value[productKey].hasOwnProperty('alternateKeys')) products.value[productKey]['alternateKeys'] = productInfoDefaultValue.alternateKeys.split(productsImportExportArrayDelimiter)
            })
        })
        .catch(error => {
            console.log(error)
            products.value = {}
            isProductsLoading.value = false
        })
}

function handlePublishProduct(ev) {

    const target = ev.target

    target.classList.add('is-loading')

    const s3Client = new S3Client({
        region: process.env.CF_R2_REGION,
        endpoint: process.env.CF_R2_ENDPOIN,
        credentials: {
            accessKeyId: s3Credentials.value.accessKeyId,
            secretAccessKey: s3Credentials.value.secretAccessKey,
        }
    })

    let productsJsonPublic = {}
    let productsJsonProtected = {}

    Object.keys(products.value).forEach(key => {
        productsJsonProtected[key] = {
            price: products.value[key].price
        }

        productsJsonPublic[key] = {
            key: products.value[key].key || productInfoDefaultValue.key,
            sku: products.value[key].sku || productInfoDefaultValue.sku,
            barcode: products.value[key].barcode || productInfoDefaultValue.barcode,
            status: products.value[key].status || productInfoDefaultValue.status,
            name: products.value[key].name || productInfoDefaultValue.name,
            moq: products.value[key].moq || productInfoDefaultValue.moq,
            price: products.value[key].price || productInfoDefaultValue.price,
            retail: products.value[key].retail || productInfoDefaultValue.retail,
            qtyAvailable: products.value[key].qtyAvailable || productInfoDefaultValue.qtyAvailable,
            eta: products.value[key].eta || productInfoDefaultValue.eta,
            position: products.value[key].position || productInfoDefaultValue.position,
            specialText: products.value[key].specialText || productInfoDefaultValue.specialText,
            description: products.value[key].description || productInfoDefaultValue.description,
            additionalInfo: products.value[key].additionalInfo || productInfoDefaultValue.additionalInfo,
            images: products.value[key].images || productInfoDefaultValue.images,
            tags: products.value[key].tags || productInfoDefaultValue.tags,
            filters: products.value[key].filters || productInfoDefaultValue.filters,
            alternateKeys: products.value[key].alternateKeys || productInfoDefaultValue.alternateKeys
        }

    })

    const command = new PutObjectCommand({
        Bucket: 'static',
        Key: selectedApp.value + "/products.json",
        Body: JSON.stringify(productsJsonPublic),
        ContentType: "application/json",
        CacheControl: "public, max-age=3600, s-maxage=600"
    })

    s3Client.send(command)
        .then(res => {
            return
        })
        .then(() => {
            const docRef = doc(getFirestore(), selectedApp.value, "products")
            return setDoc(docRef, {
                json: JSON.stringify(productsJsonProtected)
            })
        })
        .then(() => {
            toast({
                message: "Success!",
                type: 'is-success'
            })
            target.classList.remove('is-loading')
            refreshProducts()
        })
        .catch(error => {
            console.log(error)
            toast({
                message: "Some error occurred.",
                type: 'is-danger'
            })
            target.classList.remove('is-loading')
        })
}

function handleProductEditFormSubmit(ev) {
    const form = ev.target
    const btn_submit_el = form['btn_submit']
    btn_submit_el.classList.add('is-loading')

    const product = {
        key: productInfo.value['key'] || productInfoDefaultValue.key,
        sku: productInfo.value['sku'] || productInfoDefaultValue.sku,
        barcode: productInfo.value['barcode'] || productInfoDefaultValue.barcode,
        status: productInfo.value['status'] || productInfoDefaultValue.status,
        name: productInfo.value['name'] || productInfoDefaultValue.name,
        moq: productInfo.value['moq'] || productInfoDefaultValue.moq,
        price: productInfo.value['price'] || productInfoDefaultValue.price,
        retail: productInfo.value['retail'] || productInfoDefaultValue.retail,
        qtyAvailable: productInfo.value['qtyAvailable'] || productInfoDefaultValue.qtyAvailable,
        eta: productInfo.value['eta'] || productInfoDefaultValue.eta,
        position: productInfo.value['position'] || productInfoDefaultValue.position,
        specialText: productInfo.value['specialText'] || productInfoDefaultValue.specialText,
        description: productInfo.value['description'] || productInfoDefaultValue.description,
        additionalInfo: productInfo.value['additionalInfo'] || productInfoDefaultValue.additionalInfo,
        images: productInfo.value['images'].split('\n') || productInfoDefaultValue.images.split('\n'),
        tags: productInfo.value['tags'].split('\n') || productInfoDefaultValue.tags.split('\n'),
        filters: productInfo.value['filters'].split('\n') || productInfoDefaultValue.filters.split('\n'),
        alternateKeys: productInfo.value['alternateKeys'].split('\n') || productInfoDefaultValue.alternateKeys.split('\n')

    }

    products.value[productInfo.value.key] = (product)
    btn_submit_el.classList.remove('is-loading')
    form.reset()
    isProductEditModalOpen.value = false
}

function handleProductRemoval(ev) {
    const target = ev.target

    target.classList.add('is-loading')
    if (product_key.value && products.value.hasOwnProperty(product_key.value)) delete products.value[product_key.value]
    target.classList.remove('is-loading')
    isProductEditModalOpen.value = false
}

function showProductEditModal(productKey = null) {

    if (productKey) {
        productEditFormButtonLabel.value = "Update"
        productInfo.value["key"] = products.value[productKey].key || productInfoDefaultValue.key
        productInfo.value["sku"] = products.value[productKey].sku || productInfoDefaultValue.sku
        productInfo.value["barcode"] = products.value[productKey].barcode || productInfoDefaultValue.barcode
        productInfo.value["status"] = products.value[productKey].status || productInfoDefaultValue.status
        productInfo.value["name"] = products.value[productKey].name || productInfoDefaultValue.name
        productInfo.value["moq"] = products.value[productKey].moq || productInfoDefaultValue.moq
        productInfo.value["price"] = products.value[productKey].price || productInfoDefaultValue.price
        productInfo.value["retail"] = products.value[productKey].retail || productInfoDefaultValue.retail
        productInfo.value["qtyAvailable"] = products.value[productKey].qtyAvailable || productInfoDefaultValue.qtyAvailable
        productInfo.value["eta"] = products.value[productKey].eta || productInfoDefaultValue.eta
        productInfo.value["position"] = products.value[productKey].position || productInfoDefaultValue.position
        productInfo.value["specialText"] = products.value[productKey].specialText || productInfoDefaultValue.specialText
        productInfo.value["description"] = products.value[productKey].description || productInfoDefaultValue.description
        productInfo.value["additionalInfo"] = products.value[productKey].additionalInfo || productInfoDefaultValue.additionalInfo
        productInfo.value["images"] = products.value[productKey].images.join('\n') || productInfoDefaultValue.images
        productInfo.value["tags"] = products.value[productKey].tags.join('\n') || productInfoDefaultValue.tags
        productInfo.value["filters"] = products.value[productKey].filters.join('\n') || productInfoDefaultValue.filters
        productInfo.value["alternateKeys"] = products.value[productKey].alternateKeys.join('\n') || productInfoDefaultValue.alternateKeys
    }
    else {
        productEditFormButtonLabel.value = "Add"
        productInfo.value["key"] = productInfoDefaultValue.key
        productInfo.value["sku"] = productInfoDefaultValue.sku
        productInfo.value["barcode"] = productInfoDefaultValue.barcode
        productInfo.value["status"] = productInfoDefaultValue.status
        productInfo.value["name"] = productInfoDefaultValue.name
        productInfo.value["moq"] = productInfoDefaultValue.moq
        productInfo.value["price"] = productInfoDefaultValue.price
        productInfo.value["retail"] = productInfoDefaultValue.retail
        productInfo.value["qtyAvailable"] = productInfoDefaultValue.qtyAvailable
        productInfo.value["eta"] = productInfoDefaultValue.eta
        productInfo.value["position"] = productInfoDefaultValue.position
        productInfo.value["specialText"] = productInfoDefaultValue.specialText
        productInfo.value["description"] = productInfoDefaultValue.description
        productInfo.value["additionalInfo"] = productInfoDefaultValue.additionalInfo
        productInfo.value["images"] = productInfoDefaultValue.images
        productInfo.value["tags"] = productInfoDefaultValue.tags
        productInfo.value["filters"] = productInfoDefaultValue.filters
        productInfo.value["alternateKeys"] = productInfoDefaultValue.alternateKeys
    }

    isProductEditModalOpen.value = true
}

function showProductImportExportModal() {
    isProductImportExportModalOpen.value = true
}

function handleProductsImport(ev) {
    const form = ev.target

    const btn_submit_el = form['btn_submit']
    btn_submit_el.classList.add('is-loading')

    const action = form['import_action'].value || 'add'
    const files = import_files.value
    const file = files[0]

    if (typeof (FileReader) != 'undefined') {
        let reader = new FileReader()

        reader.onload = function (e) {
            let wb = read(e.target.result, {
                dense: true
            })

            if (wb.SheetNames.includes('products')) {
                let ws = wb.Sheets['products']

                const headerRow = ws[0]
                let isValidFile = true
                productsImportExportHeaders.forEach((heading, i) => {
                    if (headerRow[i].v !== heading) isValidFile = false
                })

                if (isValidFile) {
                    const wsData = xlsxUtils.sheet_to_json(ws, {
                        defval: ''
                    })
                    switch (action) {
                        case 're-upload':
                            products.value = {}
                            wsData.forEach(data => {
                                const productData = {
                                    key: data.key || productInfoDefaultValue.key,
                                    sku: data.sku || productInfoDefaultValue.sku,
                                    barcode: data.barcode || productInfoDefaultValue.barcode,
                                    status: data.status || productInfoDefaultValue.status,
                                    name: data.name || productInfoDefaultValue.name,
                                    moq: data.moq || productInfoDefaultValue.moq,
                                    price: data.price || productInfoDefaultValue.price,
                                    retail: data.retail || productInfoDefaultValue.retail,
                                    qtyAvailable: data.qtyAvailable || productInfoDefaultValue.qtyAvailable,
                                    eta: data.eta || productInfoDefaultValue.eta,
                                    position: data.position || productInfoDefaultValue.position,
                                    specialText: data.specialText || productInfoDefaultValue.specialText,
                                    description: data.description || productInfoDefaultValue.description,
                                    additionalInfo: data.additionalInfo || productInfoDefaultValue.additionalInfo,
                                    images: data.images.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.images.split(productsImportExportArrayDelimiter),
                                    tags: data.tags.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.tags.split(productsImportExportArrayDelimiter),
                                    filters: data.filters.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.filters.split(productsImportExportArrayDelimiter),
                                    alternateKeys: data.alternateKeys.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.alternateKeys.split(productsImportExportArrayDelimiter)
                                }
                                products.value[data.key] = productData
                            })
                            break;
                        case 'update':
                            wsData.forEach(data => {
                                const productData = {
                                    key: data.key || productInfoDefaultValue.key,
                                    sku: data.sku || productInfoDefaultValue.sku,
                                    barcode: data.barcode || productInfoDefaultValue.barcode,
                                    status: data.status || productInfoDefaultValue.status,
                                    name: data.name || productInfoDefaultValue.name,
                                    moq: data.moq || productInfoDefaultValue.moq,
                                    price: data.price || productInfoDefaultValue.price,
                                    retail: data.retail || productInfoDefaultValue.retail,
                                    qtyAvailable: data.qtyAvailable || productInfoDefaultValue.qtyAvailable,
                                    eta: data.eta || productInfoDefaultValue.eta,
                                    position: data.position || productInfoDefaultValue.position,
                                    specialText: data.specialText || productInfoDefaultValue.specialText,
                                    description: data.description || productInfoDefaultValue.description,
                                    additionalInfo: data.additionalInfo || productInfoDefaultValue.additionalInfo,
                                    images: data.images.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.images.split(productsImportExportArrayDelimiter),
                                    tags: data.tags.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.tags.split(productsImportExportArrayDelimiter),
                                    filters: data.filters.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.filters.split(productsImportExportArrayDelimiter),
                                    alternateKeys: data.alternateKeys.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.alternateKeys.split(productsImportExportArrayDelimiter)
                                }
                                products.value[productData.key] = productData
                            })
                            break;
                        case 'add':
                            wsData.forEach(data => {
                                const productData = {
                                    key: data.key || productInfoDefaultValue.key,
                                    sku: data.sku || productInfoDefaultValue.sku,
                                    barcode: data.barcode || productInfoDefaultValue.barcode,
                                    status: data.status || productInfoDefaultValue.status,
                                    name: data.name || productInfoDefaultValue.name,
                                    moq: data.moq || productInfoDefaultValue.moq,
                                    price: data.price || productInfoDefaultValue.price,
                                    retail: data.retail || productInfoDefaultValue.retail,
                                    qtyAvailable: data.qtyAvailable || productInfoDefaultValue.qtyAvailable,
                                    eta: data.eta || productInfoDefaultValue.eta,
                                    position: data.position || productInfoDefaultValue.position,
                                    specialText: data.specialText || productInfoDefaultValue.specialText,
                                    description: data.description || productInfoDefaultValue.description,
                                    additionalInfo: data.additionalInfo || productInfoDefaultValue.additionalInfo,
                                    images: data.images.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.images.split(productsImportExportArrayDelimiter),
                                    tags: data.tags.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.tags.split(productsImportExportArrayDelimiter),
                                    filters: data.filters.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.filters.split(productsImportExportArrayDelimiter),
                                    alternateKeys: data.alternateKeys.split(productsImportExportArrayDelimiter) || productInfoDefaultValue.alternateKeys.split(productsImportExportArrayDelimiter)
                                }
                                if (!products.value.hasOwnProperty(productData.key)) products.value[productData.key] = productData
                            })
                            break;

                        default:
                            alert("Invalid action.")
                            break;
                    }

                }
                else alert("Invalid file.")

            }
            else alert("Invalid file.")

        }

        reader.readAsArrayBuffer(file)
    }
    form.reset()
    btn_submit_el.classList.remove('is-loading')
    isProductImportExportModalOpen.value = false
}

function handleProductsExport(ev) {

    const btn_el = ev.target

    btn_el.classList.add('is-loading')

    if (Object.keys(products.value).length) {

        let wb = xlsxUtils.book_new()
        let productsData = [productsImportExportHeaders]

        if (Object.keys(products.value).length) {
            Object.keys(products.value).forEach(key => {
                try {
                    const data = [
                        products.value[key].key || productInfoDefaultValue.key,
                        products.value[key].sku || productInfoDefaultValue.sku,
                        products.value[key].barcode || productInfoDefaultValue.barcode,
                        products.value[key].status || productInfoDefaultValue.status,
                        products.value[key].name || productInfoDefaultValue.name,
                        products.value[key].moq || productInfoDefaultValue.moq,
                        products.value[key].price || productInfoDefaultValue.price,
                        products.value[key].retail || productInfoDefaultValue.retail,
                        products.value[key].qtyAvailable || productInfoDefaultValue.qtyAvailable,
                        products.value[key].eta || productInfoDefaultValue.eta,
                        products.value[key].position || productInfoDefaultValue.position,
                        products.value[key].specialText || productInfoDefaultValue.specialText,
                        products.value[key].description || productInfoDefaultValue.description,
                        products.value[key].additionalInfo || productInfoDefaultValue.additionalInfo,
                        products.value[key].images.join(productsImportExportArrayDelimiter) || productInfoDefaultValue.images,
                        products.value[key].tags.join(productsImportExportArrayDelimiter) || productInfoDefaultValue.tags,
                        products.value[key].filters.join(productsImportExportArrayDelimiter) || productInfoDefaultValue.filters,
                        products.value[key].alternateKeys.join(productsImportExportArrayDelimiter) || productInfoDefaultValue.alternateKeys
                    ]
                    productsData.push(data)
                } catch (error) {
                    console.log(error)
                }

            })
        }

        const ws = xlsxUtils.aoa_to_sheet(productsData)
        xlsxUtils.book_append_sheet(wb, ws, "products")

        const timestamp = new Date()
        writeFileXLSX(wb, `products-export-${timestamp.toISOString()}.xlsx`)

    }

    btn_el.classList.remove('is-loading')

}

function handleProductSearch(ev) {
    const form = ev.target

    const btn_submit_el = form["btn_submit"]
    btn_submit_el.classList.add('is-loading')

    form.reset()

    btn_submit_el.classList.remove('is-loading')
}

</script>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                    Products
                </h1>
                <!-- <p class="subtitle"></p> -->
            </div>
        </div>
    </section>

    <section class="section has-background-primary-light">
        <div class="container">
            <p class="has-background-warning has-text-warning-dark has-text-centered mb-3">All changes will be lost if not
                published before selecting different app, or navigating away from this page.
                <br>
                It may take few minutes for before customers can see changes.
            </p>
            <div class="field">
                <label class="label is-visually-hidden">Select app</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model="selectedApp">
                            <option v-for="app in appList" :value=app.id>{{ app.name }} ({{ app.domain }})</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="buttons is-centered" v-show="selectedApp">
                <button type="button" class="button is-link" :class="{ 'is-loading': isProductsLoading }"
                    @click="refreshProducts">↻ Refresh</button>
                <button id="btn_add_new_product" type="button" class="button is-link is-outlined"
                    :class="{ 'is-loading': isProductEditModalOpen }" @click="showProductEditModal()">Add
                    new product</button>
                <button id="btn_add_new_product" type="button" class="button is-link is-outlined"
                    :class="{ 'is-loading': isProductImportExportModalOpen }"
                    @click="showProductImportExportModal()">Import/Export</button>
                <button type="button" class="button is-primary is-outlined" @click="handlePublishProduct($event)">Publish
                    Changes</button>
            </div>
        </div>
    </section>

    <section class="section">
        <div v-if="selectedApp" class="container">
            <div v-if="isProductsLoading" class="mx-auto has-text-centered">
                <!-- <img src="./assets/preloader.svg" alt="Loading spinner animation"> -->
                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="128" height="128" xml:space="preserve">
                    <g>
                        <path
                            d="M64 128A64 64 0 0 1 18.34 19.16L21.16 22a60 60 0 1 0 52.8-17.17l.62-3.95A64 64 0 0 1 64 128z"
                            fill="#333" />
                        <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1800ms"
                            repeatCount="indefinite" />
                    </g>
                </svg>
                <p>Loading...</p>
            </div>
            <div v-else-if="Object.keys(products).length" class="columns is-centered is-multiline">
                <div v-for="product in products"
                    class="column is-one-quarter-widescreen is-one-third-desktop is-one-third-tablet">
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-header-title is-fullheight is-clickable"
                                @click="showProductEditModal(product.key)">
                                {{ product.key }}
                            </h2>
                            <span class="card-header-icon has-cursor-default">{{ product.status }}<strong
                                    class="has-text-primary has-animation-blink"></strong></span>
                        </div>
                        <div class="card-image">
                            <figure class="image is-square is-contain">
                                <img v-if="product.images" :src='product.images[0]' :alt="product.name" width="600"
                                    height="600">
                                <img v-else src='/assets/logo-nfe-black-512x512.png' alt="Image not found" width="600"
                                    height="600">
                            </figure>
                            <span class="card-header-icon has-cursor-default py-0"><strong
                                    class="has-text-primary has-animation-blink"> {{ product.specialText }}</strong></span>
                        </div>
                        <div class="card-content">
                            <p class="has-text-centered">{{ product.name }}</p>
                            <div class="columns is-multiline mt-2">
                                <span class="column is-half py-0">SKU: {{ product.sku }}</span>
                                <span class="column is-half py-0">MOQ: {{ product.moq }}</span>
                                <span class="column is-half py-0">COST: {{ formatterCurrency.format(product.price) }}</span>
                                <span class="column is-half py-0">RETAIL: {{ formatterCurrency.format(product.retail)
                                }}</span>
                            </div>
                        </div>
                        <div class="card-footer is-flex-direction-column">
                            <button class="card-footer-item button is-text" type="submit">Add to cart</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else="products === null">
                <p class="is-size-4 has-text-centered">
                    No products found for selected app.
                </p>
            </div>
        </div>
        <div v-else class="container">
            <p class="is-size-4 has-text-centered">
                Please, select an app first.
            </p>

        </div>

    </section>

    <Teleport to="#modals">
        <div class="modal" :class="{ 'is-active': isProductEditModalOpen }">
            <div class="modal-background" @click="isProductEditModalOpen = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <h2 class="modal-card-title">{{ productEditFormButtonLabel }} product</h2>
                    <button class="delete" aria-label="close" @click="isProductEditModalOpen = false"></button>
                </header>
                <section class="modal-card-body">
                    <form action="products/add-new-product" method="post"
                        @submit.prevent="handleProductEditFormSubmit($event)">
                        <div class="field">
                            <label class="label">Key (slug)</label>
                            <div class="control">
                                <input v-model="productInfo.key" class="input" type="text"
                                    placeholder="Enter valid slug here."
                                    pattern="[a-z0-9](?!.*[\-]{2})([a-z0-9\-]+)[a-z0-9]" required
                                    @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })">
                            </div>
                            <div class="help content mt-0">
                                A valid key...
                                <ul>
                                    <li>Only contain lowercase letters(a-z), numbers(0-9), and hyphens(-).</li>
                                    <li>Must start and end with a lowercase letter or a number.</li>
                                    <li>Must not contain double hyphens(--)</li>
                                </ul>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">SKU</label>
                            <div class="control">
                                <input v-model="productInfo.sku" class="input" type="text" placeholder="AW00000" required
                                    @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Barcode</label>
                            <div class="control">
                                <input v-model="productInfo.barcode" class="input" type="text" placeholder="092943000001">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label is-visually-hidden">Status</label>
                            <div class="control">
                                <div class="select is-fullwidth">
                                    <select v-model="productInfo.status" required>
                                        <option value="A">Active</option>
                                        <option value="I">Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Name</label>
                            <div class="control">
                                <input v-model="productInfo.name" class="input" type="text" placeholder="Product Name"
                                    required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label"><abbr title="Minimum Order Quantity">MOQ</abbr></label>
                            <div class="control">
                                <input v-model.number="productInfo.moq" class="input" type="number" min="1" step="1"
                                    placeholder="Enter minimum order quantity." required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Price</label>
                            <div class="control">
                                <input v-model.number="productInfo.price" class="input" type="number" step="0.01"
                                    placeholder="Enter unit price." required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Retail</label>
                            <div class="control">
                                <input v-model.number="productInfo.retail" class="input" type="number" step="0.01"
                                    placeholder="Enter retail price." required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Position</label>
                            <div class="control">
                                <input v-model.number="productInfo.position" class="input" type="number" inputmode="numeric"
                                    step="1" placeholder="Products will appear in decending order." required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Qty. available</label>
                            <div class="control">
                                <input v-model="productInfo.qtyAvailable" class="input" type="text" step="1"
                                    placeholder="Enter a number or InStock/OutOfStock" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label"><abbr title="Extimated Time of Arrival">ETA</abbr></label>
                            <div class="control">
                                <input v-model="productInfo.eta" class="input" type="text" placeholder="ETA">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Special text</label>
                            <div class="control">
                                <input v-model="productInfo.specialText" class="input" type="text"
                                    placeholder="Special Text">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Description</label>
                            <textarea v-model="productInfo.description" class="textarea"
                                placeholder="Product description goes here. It will appear in additional info section."></textarea>
                        </div>
                        <div class="field">
                            <label class="label">Additional info</label>
                            <textarea v-model="productInfo.additionalInfo" class="textarea"
                                placeholder="Product description goes here. It will appear in additional info section."></textarea>
                        </div>
                        <div class="field">
                            <label class="label">Images</label>
                            <textarea v-model="productInfo.images" class="textarea"
                                placeholder="Enter one image name per line."></textarea>
                        </div>
                        <div class="field">
                            <label class="label">Tags</label>
                            <textarea v-model="productInfo.tags" class="textarea"
                                placeholder="Enter one tag per line."></textarea>
                        </div>
                        <div class="field">
                            <label class="label">Filters</label>
                            <textarea v-model="productInfo.filters" class="textarea"
                                placeholder="Enter one filter per line."></textarea>
                        </div>
                        <div class="field">
                            <label class="label">Alternate products</label>
                            <textarea v-model="productInfo.alternateKeys" class="textarea"
                                placeholder="Enter one alternate product key per line."></textarea>
                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <button id="btn_submit" type="submit" class="button is-link">{{ productEditFormButtonLabel
                                }}</button>
                            </div>
                            <div class="control">
                                <button type="reset" class="button is-link is-light">Reset</button>
                            </div>
                            <div class="control">
                                <button type="button" class="button is-danger is-outlined"
                                    @click="handleProductRemoval($event)">Remove</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </Teleport>
    <Teleport to="#modals">
        <div class="modal" :class="{ 'is-active': isProductImportExportModalOpen }">
            <div class="modal-background" @click="isProductImportExportModalOpen = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <h2 class="modal-card-title">Import/Export Products</h2>
                    <button class="delete" aria-label="close" @click="isProductImportExportModalOpen = false"></button>
                </header>
                <section class="modal-card-body">
                    <h3 class="is-size-4 mb-3">Import Products</h3>
                    <form action="products/import" method="post" @submit.prevent="handleProductsImport($event)">
                        <div class="field">
                            <label class="label" for="import_action">Select action</label>
                            <div class="control">
                                <div class="select">
                                    <select id="import_action" required>
                                        <option value="add">Add new and skip existing items.</option>
                                        <option value="update">Update existing and add new items.</option>
                                        <option value="re-upload">Delete all and re-upload items.</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="field">
                            <div class="file has-name">
                                <label class="file-label" for="import_files">
                                    <input id="import_files" class="file-input" type="file" required
                                        @change="import_files = $event.target.files" accept=".xlsx">
                                    <span class="file-cta">
                                        <span class="file-label">
                                            Select files...
                                        </span>
                                    </span>
                                    <span class="file-name">
                                        {{ import_files.length }} Selected
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div class="field is-grouped">
                            <div class="control">
                                <button id="btn_submit" type="submit" class="button is-link">Import</button>
                            </div>
                            <div class="control">
                                <button type="reset" class="button is-link is-light">Reset</button>
                            </div>
                        </div>
                    </form>
                    <hr>
                    <h3 class="is-size-4 mb-3">Export Products</h3>
                    <div class="field is-grouped">
                        <div class="control">
                            <button type="button" class="button is-link" @click="handleProductsExport($event)">Export
                                (.xlsx)</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </Teleport>
</template>