import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"
import { getAnalytics } from "firebase/analytics"


export function initFirebase() {
    const app = initializeApp({
        apiKey: process.env.FBASE_API_KEY,
        authDomain: process.env.FBASE_AUTH_DOMAIN,
        projectId: process.env.FABSE_PROJECT_ID,
        storageBucket: process.env.FABSE_STORAGE_BCKET,
        messagingSenderId: process.env.FBASE_MESSAGING_SENDER_ID,
        appId: process.env.FBASE_APP_ID,
        measurementId: process.env.FBASE_MEASUREMENT_ID
    });
    console.info("Firebase: App initialised.", app)

    // const analytics = getAnalytics(app);
}

export function addListnerForAuthStateVisibility() {
    onAuthStateChanged(getAuth(), (user) => {
        console.info("Firebase: User auth state changed.")
        if (user) {
            console.info("Firebase: User signed in")
            document.body.classList.remove('user-signed-out')
            document.body.classList.add('user-signed-in')
        }
        else {
            document.body.classList.remove('user-signed-in')
            document.body.classList.add('user-signed-out')
        }
        // getAuth().currentUser.getIdToken().then(idToken => console.log(idToken))
    })
}

export async function handleSignOut() {
    await signOut(getAuth())
}