// Create our number formatter.
export const formatterCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',

    //These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const removeAllChildNodes = (parent) => {
    while (parent.firstChild) {
        parent.removeChild(parent.firstChild);
    }
}

export const showSignInMessage = () => {
    alert("You must sign in first!")
}

export const handleTelInputs = () => {
    const telInputs = document.querySelectorAll('input[type="tel"]')

    telInputs.forEach(telInput => {
        telInput.addEventListener('input', (e) => {
            let input = telInput.value.replace(/\D/g, '');
            const length = input.length;
            if (length > 0) { input = "(" + input }
            if (length > 3) { input = input.slice(0, 4) + ") " + input.slice(4, 11) }
            if (length > 6) { input = input.slice(0, 9) + "-" + input.slice(9) }
            telInput.value = input
        })
    })
}