import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useConfigStore = defineStore('config', () => {
    
    const appList = ref([
        {
            id: "1:961039456541:web:6db84bb9ae7c773868a9f4",
            name: "Dev",
            domain: "dev-nearly-famous.web.app"
        },
        {
            id: "1:961039456541:web:c9367356d139bd5068a9f4",
            name: "Main",
            domain: "nearly-famous.web.app"
        },
        {
            id: "1:961039456541:web:022e138977f3e01668a9f4",
            name: "B2B",
            domain: "b2b-nearly-famous.web.app"
        },
        {
            id: "1:961039456541:web:3443cbdd6688a0bc68a9f4",
            name: "Rep",
            domain: "rep.nearlyfamous.ca"
        },
        {
            id: "1:961039456541:web:663d44ab23b74c2468a9f4",
            name: "Admin",
            domain: "admin.nearlyfamous.ca"
        },
    ])

    return { appList }
})