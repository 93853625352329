import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useNavigationStore = defineStore('navigation', () => {
    const isNavMenuOpen = ref(false)

    const pathToRedirectAfterSignin = ref("/")

    function openNavMenu() {
        isNavMenuOpen.value = true
    }

    function closeNavMenu() {
        isNavMenuOpen.value = false
    }

    function toggleNavMenu() {
        isNavMenuOpen.value = !isNavMenuOpen.value
    }

    return { pathToRedirectAfterSignin, isNavMenuOpen, openNavMenu, closeNavMenu, toggleNavMenu }
})