<script setup>


</script>

<template>
    <section class="hero is-fullheight-with-navbar">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                Home
            </h1>
            <p class="subtitle">
                Welcome to NFE Admin Panel.
            </p>
            </div>
        </div>
    </section>
</template>