<script setup>
import { RouterView } from 'vue-router'
import Header from './routes/partials/Header.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from "./scripts/stores/user"
import router from "./scripts/router"

const { isAuthStateInitialised } = storeToRefs(useUserStore())

console.log(router.currentRoute.value.path)

</script>

<template>
    <div v-if="isAuthStateInitialised">
        <Header v-if="router.currentRoute.value.path !== '/signin'"></Header>
        <RouterView />
        <div id="modals"></div>
    </div>
    <div v-else>
        <section class="hero is-fullheight">
            <div class="hero-body">
                <div class="mx-auto has-text-centered">
                    <!-- <img src="./assets/preloader.svg" alt="Loading spinner animation"> -->
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="128" height="128" xml:space="preserve">
                        <g>
                            <path
                                d="M64 128A64 64 0 0 1 18.34 19.16L21.16 22a60 60 0 1 0 52.8-17.17l.62-3.95A64 64 0 0 1 64 128z"
                                fill="#333" />
                            <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64"
                                dur="1800ms" repeatCount="indefinite" />
                        </g>
                    </svg>
                    <p>Loading...</p>
                </div>
            </div>
        </section>
    </div>
</template>
