<script setup>
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useConfigStore } from '../scripts/stores/config';
import { toast } from 'bulma-toast';
import { storeToRefs } from "pinia";
import { watch, ref, onMounted } from 'vue';

const { appList } = storeToRefs(useConfigStore())

const selectedApp = ref(process.env.FBASE_APP_ID)
const isCataloguesLoading = ref(false)
const isCatalogueEditModalOpen = ref(false)
const catalogueEditFormButtonLabel = ref('Add')
const catalogues = ref({})

const catalogue_key = ref('')
const catalogue_position = ref(0)
const catalogue_name = ref('')
const catalogue_description = ref('')
const catalogue_special_text = ref('')
const catalogue_image = ref('')
const catalogue_pdf = ref('')
const catalogue_pdf_priced = ref('')
const catalogue_pdf_order_form = ref('')

const s3Credentials = ref({})

onMounted(() => {
    const functions = getFunctions()
    const getCfR2Keys = httpsCallable(functions, 'getCfR2Keys')

    getCfR2Keys()
        .then(response => {
            const cfR2Credentials = response.data
            if (cfR2Credentials.hasOwnProperty('accessKeyId') && cfR2Credentials.hasOwnProperty('secretAccessKey')) {
                s3Credentials.value = cfR2Credentials
            }
            else {
                console.log("Can not find R2 credentials.")
            }
        })
})

watch(selectedApp, () => {
    refreshCatalogues()
})

watch(isCatalogueEditModalOpen, (isOpen) => {
    if (!isOpen) {
        document.getElementById('btn_add_new_catalogue').classList.remove('is-loading')
    }
})

async function refreshCatalogues() {
    isCataloguesLoading.value = true
    catalogues.value = {}
    // Use https://cache-bypassed.static.nearlyfamous.ca/ instead of https://static.nearlyfamous.ca/ to get the resource directly(uncached).
    const url = "https://cache-bypassed.static.nearlyfamous.ca/" + selectedApp.value + "/catalogues.json"
    fetch(url, {cache: 'no-cache'})
        .then(res => res.json())
        .then(cataloguesData => {
            if (typeof cataloguesData === 'object' && !Array.isArray(cataloguesData)) {
                catalogues.value = Object.fromEntries(Object.entries(cataloguesData).sort(([,a],[,b]) => b.position - a.position))
            }
            else catalogues.value = {}
            isCataloguesLoading.value = false
        })
        .catch(error => {
            console.log(error)
            catalogues.value = {}
            isCataloguesLoading.value = false
        })
}

function handlePublishCatalogues(ev) {

    const target = ev.target

    target.classList.add('is-loading')

    const s3Client = new S3Client({
        region: process.env.CF_R2_REGION,
        endpoint: process.env.CF_R2_ENDPOIN,
        credentials: {
            accessKeyId: s3Credentials.value.accessKeyId,
            secretAccessKey: s3Credentials.value.secretAccessKey,
        }
    })

    const command = new PutObjectCommand({
        Bucket: 'static',
        Key: selectedApp.value + "/catalogues.json",
        Body: JSON.stringify(catalogues.value),
        ContentType: "application/json",
        CacheControl: "public, max-age=3600, s-maxage=600"
    })

    s3Client.send(command)
        .then(res => {
            console.log(res)
            toast({
                message: "Success!",
                type: 'is-success'
            })
            target.classList.remove('is-loading')
            refreshCatalogues()
        })
        .catch(error => {
            console.log(error)
            toast({
                message: "Some error occurred.",
                type: 'is-danger'
            })
            target.classList.remove('is-loading')
        })
}

function handleCatalogueEditFormSubmit(ev) {
    const form = ev.target
    console.log("handleCatalogueEditFormSubmit")
    const btn_submit_el = form['btn_submit']
    btn_submit_el.classList.add('is-loading')

    const catalogue = {
        key: catalogue_key.value,
        position: catalogue_position.value,
        name: catalogue_name.value,
        description: catalogue_description.value,
        specialText: catalogue_special_text.value,
        image: catalogue_image.value,
        pdf: catalogue_pdf.value,
        pdfPriced: catalogue_pdf_priced.value,
        pdfOrderForm: catalogue_pdf_order_form.value
    }

    catalogues.value[catalogue_key.value] = (catalogue)
    btn_submit_el.classList.remove('is-loading')
    form.reset()
    isCatalogueEditModalOpen.value = false
}

function handleCatalogueRemoval(ev) {
    const target = ev.target

    target.classList.add('is-loading')
    if (catalogue_key.value && catalogues.value.hasOwnProperty(catalogue_key.value)) delete catalogues.value[catalogue_key.value]
    target.classList.remove('is-loading')
    isCatalogueEditModalOpen.value = false
}

function showCatalogueEditModal(catalogueKey = null) {

    if (catalogueKey) {
        catalogueEditFormButtonLabel.value = "Update"
        catalogue_key.value = catalogues.value[catalogueKey].key || ''
        catalogue_position.value = catalogues.value[catalogueKey].position || 0
        catalogue_name.value = catalogues.value[catalogueKey].name || ''
        catalogue_description.value = catalogues.value[catalogueKey].description || ''
        catalogue_special_text.value = catalogues.value[catalogueKey].specialText || ''
        catalogue_image.value = catalogues.value[catalogueKey].image || ''
        catalogue_pdf.value = catalogues.value[catalogueKey].pdf || ''
        catalogue_pdf_priced.value = catalogues.value[catalogueKey].pdfPriced || ''
        catalogue_pdf_order_form.value = catalogues.value[catalogueKey].pdfOrderForm || ''
    }
    else {
        catalogueEditFormButtonLabel.value = "Add"
        catalogue_key.value = ''
        catalogue_position.value = 0
        catalogue_name.value = ''
        catalogue_description.value = ''
        catalogue_special_text.value = ''
        catalogue_image.value = ''
        catalogue_pdf.value = ''
        catalogue_pdf_priced.value = ''
        catalogue_pdf_order_form.value = ''
    }

    isCatalogueEditModalOpen.value = true
}

</script>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                    Catalogues
                </h1>
                <!-- <p class="subtitle">
                    Change any registered user's password.
                </p> -->
            </div>
        </div>
    </section>

    <section class="section has-background-light">
        <div class="container">
            <p class="has-background-warning has-text-warning-dark has-text-centered mb-3">All changes will be lost if not
                published before selecting different app, or navigating away from this page.
                <br>
                It may take few minutes for before customers can see changes.
            </p>
            <div class="field">
                <label class="label is-visually-hidden">Select app</label>
                <div class="control">
                    <div class="select is-fullwidth">
                        <select v-model="selectedApp">
                            <option v-for="app in appList" :value=app.id>{{ app.name }} ({{ app.domain }})</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="buttons is-centered" v-show="selectedApp">
                <button type="button" class="button is-link" :class="{ 'is-loading': isCataloguesLoading }"
                    @click="refreshCatalogues">↻ Refresh</button>
                <button id="btn_add_new_catalogue" type="button" class="button is-link is-outlined"
                    :class="{ 'is-loading': isCatalogueEditModalOpen }" @click="showCatalogueEditModal()">Add
                    new catalogue</button>
                <button type="button" class="button is-primary is-outlined" @click="handlePublishCatalogues($event)">Publish
                    Changes</button>
            </div>
        </div>
    </section>



    <section class="section">
        <div v-if="selectedApp" class="container">
            <div v-if="isCataloguesLoading" class="mx-auto has-text-centered">
                <!-- <img src="./assets/preloader.svg" alt="Loading spinner animation"> -->
                <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="128" height="128" xml:space="preserve">
                    <g>
                        <path
                            d="M64 128A64 64 0 0 1 18.34 19.16L21.16 22a60 60 0 1 0 52.8-17.17l.62-3.95A64 64 0 0 1 64 128z"
                            fill="#333" />
                        <animateTransform attributeName="transform" type="rotate" from="0 64 64" to="360 64 64" dur="1800ms"
                            repeatCount="indefinite" />
                    </g>
                </svg>
                <p>Loading...</p>
            </div>
            <div v-else-if="Object.keys(catalogues).length" class="columns is-centered is-multiline">
                <div v-for="catalogue in catalogues"
                    class="column is-one-quarter-widescreen is-one-third-desktop is-one-third-tablet">
                    <div class="card">
                        <div class="card-header">
                            <h2 class="card-header-title is-fullheight is-clickable"
                                @click="showCatalogueEditModal(catalogue.key)">
                                {{ catalogue.name }}
                            </h2>
                            <span v-if="catalogue.specialText" class="card-header-icon has-cursor-default"><strong
                                    class="has-text-primary has-animation-blink">{{ catalogue.specialText }}</strong></span>
                        </div>
                        <div class="card-image">
                            <figure class="image">
                                <img :src="catalogue.image" :alt="catalogue.name" width="612" height="792">
                            </figure>
                            <div class="card-content">
                                <p>{{ catalogue.description }}</p>
                            </div>
                        </div>
                        <div class="card-footer is-flex-direction-column">
                            <a target="_blank" rel="noopener" :href="catalogue.pdf" class="card-footer-item">Download</a>
                            <a v-if="catalogue.pdfPriced" target="_blank" rel="noopener" :href="catalogue.pdfPriced"
                                class="card-footer-item has-text-centered">Download (Priced)</a>
                            <a v-if="catalogue.pdfOrderForm" target="_blank" rel="noopener" :href="catalogue.pdfOrderForm"
                                class="card-footer-item is-hidden-signed-out">Order form</a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else="catalogues === null">
                <p class="is-size-4 has-text-centered">
                    No catalogues found for selected app.
                </p>
            </div>
        </div>
        <div v-else class="container">
            <p class="is-size-4 has-text-centered">
                Please, select an app first.
            </p>

        </div>

    </section>

    <Teleport to="#modals">
        <div class="modal" :class="{ 'is-active': isCatalogueEditModalOpen }">
            <div class="modal-background" @click="isCatalogueEditModalOpen = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ catalogueEditFormButtonLabel }} catalogue</p>
                    <button class="delete" aria-label="close" @click="isCatalogueEditModalOpen = false"></button>
                </header>
                <section class="modal-card-body">
                    <form action="catalogues/add-new-catalogue" method="post"
                        @submit.prevent="handleCatalogueEditFormSubmit($event)">
                        <div class="field">
                            <label class="label">Key (slug)</label>
                            <div class="control">
                                <input v-model="catalogue_key" class="input" type="text"
                                    placeholder="Enter valid slug here."
                                    pattern="[a-z0-9](?!.*[\-]{2})([a-z0-9\-]+)[a-z0-9]" required
                                    @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })">
                            </div>
                            <div class="help content mt-0">
                                A valid key...
                                <ul>
                                    <li>Only contain lowercase letters(a-z), numbers(0-9), and hyphens(-).</li>
                                    <li>Must start and end with a lowercase letter or a number.</li>
                                    <li>Must not contain double hyphens(--)</li>
                                </ul>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Position</label>
                            <div class="control">
                                <input v-model="catalogue_position" class="input" type="number"
                                    placeholder="Catalogues will appear in decending order." required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Name</label>
                            <div class="control">
                                <input v-model="catalogue_name" class="input" type="text" placeholder="Catalogue Name"
                                    required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Description</label>
                            <textarea v-model="catalogue_description" class="textarea"
                                placeholder="Catalogue description goes here."></textarea>
                        </div>
                        <div class="field">
                            <label class="label">Special text</label>
                            <div class="control">
                                <input v-model="catalogue_special_text" class="input" type="text"
                                    placeholder="Special Text">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Image</label>
                            <div class="control">
                                <input v-model="catalogue_image" class="input" type="text"
                                    placeholder="https://static.nearlyfamous.ca/catalogues/catalogue.jpg">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">PDF</label>
                            <div class="control">
                                <input v-model="catalogue_pdf" class="input" type="text"
                                    placeholder="https://static.nearlyfamous.ca/catalogues/catalogue.pdf" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">PDF - Priced</label>
                            <div class="control">
                                <input v-model="catalogue_pdf_priced" class="input" type="text"
                                    placeholder="https://static.nearlyfamous.ca/catalogues/catalogue-cad.pdf">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">PDF - Order Form</label>
                            <div class="control">
                                <input v-model="catalogue_pdf_order_form" class="input" type="text"
                                    placeholder="https://static.nearlyfamous.ca/catalogues/catalogue-order-form.pdf">
                            </div>
                        </div>

                        <div class="field is-grouped">
                            <div class="control">
                                <button id="btn_submit" type="submit" class="button is-link">{{ catalogueEditFormButtonLabel
                                }}</button>
                            </div>
                            <div class="control">
                                <button type="reset" class="button is-link is-light">Reset</button>
                            </div>
                            <div class="control">
                                <button type="button" class="button is-danger is-outlined"
                                    @click="handleCatalogueRemoval($event)">Remove</button>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    </Teleport>
</template>