<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from 'bulma-toast';

async function handleChangeUserPassword(ev) {
    const form = ev.target

    const btn_submit_el = form['btn_submit']
    const user_email_el = form['user_email']
    const user_password_el = form['user_password']

    btn_submit_el.classList.add('is-loading')

    const functions = getFunctions()
    const changeUserPassword = httpsCallable(functions, 'changeUserPassword')

    changeUserPassword({
        email: user_email_el.value,
        password: user_password_el.value
    })
        .then(message => {
            toast({
                message: "Success!",
                type: 'is-success'
            })
            btn_submit_el.classList.remove('is-loading')
            form.reset()
        })
        .catch(error => {
            console.log(error)
            toast({
                message: "Some error occurred.",
                type: 'is-danger'
            })
            btn_submit_el.classList.remove('is-loading')
            form.reset()
        })
}

</script>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                    Change User Password
                </h1>
                <!-- <p class="subtitle">
                    Change any registered user's password.
                </p> -->
            </div>
        </div>
    </section>
    <section class="section">
        <div class="container">
            <form action="/upload-static-files" method="post" id="form_static_files"
                @submit.prevent="handleChangeUserPassword($event)">
                <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                        <input class="input" type="email" id="user_email" placeholder="Enter email of an existing user."
                            @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })"
                            required>
                    </div>
                </div>
                <div class="field">
                    <label class="label">New password</label>
                    <div class="control">
                        <input class="input" type="password" id="user_password"
                            placeholder="Enter new password for the user." minlength="8" required
                            @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })">
                    </div>
                    <div class="help content mt-0">
                        A good password...
                        <ul>
                            <li>Must be 8-64 characters long.</li>
                            <li>Should be a combination of numbers(0-9) and letters(a-z, A-Z)</li>
                            <li>Should include special characters: ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , &gt; &lt; ' : ; |
                                _ ~ `</li>
                        </ul>
                    </div>
                </div>

                <div class="field is-grouped">
                    <div class="control">
                        <button id="btn_submit" type="submit" class="button is-link">Change</button>
                    </div>
                    <div class="control">
                        <button type="reset" class="button is-link is-light">Reset</button>
                    </div>
                </div>
            </form>
        </div>
</section></template>