import { HttpResponse } from "@smithy/protocol-http";
export const throw200ExceptionsMiddleware = (config) => (next) => async (args) => {
    const result = await next(args);
    const { response } = result;
    if (!HttpResponse.isInstance(response))
        return result;
    const { statusCode, body } = response;
    if (statusCode < 200 || statusCode >= 300)
        return result;
    const bodyBytes = await collectBody(body, config);
    const bodyString = await collectBodyString(bodyBytes, config);
    if (bodyBytes.length === 0) {
        const err = new Error("S3 aborted request");
        err.name = "InternalError";
        throw err;
    }
    if (bodyString && bodyString.match("<Error>")) {
        response.statusCode = 400;
    }
    response.body = bodyBytes;
    return result;
};
const collectBody = (streamBody = new Uint8Array(), context) => {
    if (streamBody instanceof Uint8Array) {
        return Promise.resolve(streamBody);
    }
    return context.streamCollector(streamBody) || Promise.resolve(new Uint8Array());
};
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then((body) => context.utf8Encoder(body));
export const throw200ExceptionsMiddlewareOptions = {
    relation: "after",
    toMiddleware: "deserializerMiddleware",
    tags: ["THROW_200_EXCEPTIONS", "S3"],
    name: "throw200ExceptionsMiddleware",
    override: true,
};
export const getThrow200ExceptionsPlugin = (config) => ({
    applyToStack: (clientStack) => {
        clientStack.addRelativeTo(throw200ExceptionsMiddleware(config), throw200ExceptionsMiddlewareOptions);
    },
});
