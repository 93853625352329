<script setup>
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from 'bulma-toast';

async function handleAssignUserRole(ev) {
    const form = ev.target

    const btn_submit_el = form['btn_submit']
    const user_email_el = form['user_email']
    const user_role_el = form['user_role']

    btn_submit_el.classList.add('is-loading')

    const functions = getFunctions()
    const assignUserRole = httpsCallable(functions, 'assignUserRole')

    assignUserRole({
        email: user_email_el.value,
        role: user_role_el.value
    })
        .then(message => {
            toast({
                message: "Success!",
                type: 'is-success'
            })
            btn_submit_el.classList.remove('is-loading')
            form.reset()
        })
        .catch(error => {
            console.log(error)
            toast({
                message: "Some error occurred.",
                type: 'is-danger'
            })
            btn_submit_el.classList.remove('is-loading')
            form.reset()
        })
}

</script>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                    Assign User Role
                </h1>
                <!-- <p class="subtitle">
                    Assign role to any registered user.
                </p> -->
            </div>
        </div>
    </section>
    <section class="section">
        <div class="container">
            <form action="/upload-static-files" method="post" id="form_static_files"
                @submit.prevent="handleAssignUserRole($event)">
                <div class="field">
                    <label class="label">Email</label>
                    <div class="control">
                        <input class="input" type="email" id="user_email" placeholder="Enter email of an existing user."
                            @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })"
                            required>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Role</label>
                    <div class="control">
                        <div class="select">
                            <select id="user_role" required>
                                <option value="customer">Customer</option>
                                <option value="rep">Rep</option>
                                <option value="admin">Admin</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field">
                            <div class="control">
                                <label class="checkbox">
                                    <input type="checkbox">
                                    I am aware that the newly assigned role will take effect in future sign-ins, but if a user is already signed-in to any device, they may continue to access the information allowed by their previous role, until user sign-out.
                                </label>
                            </div>
                        </div>

                <div class="field is-grouped">
                    <div class="control">
                        <button id="btn_submit" type="submit" class="button is-link">Assign</button>
                    </div>
                    <div class="control">
                        <button type="reset" class="button is-link is-light">Reset</button>
                    </div>
                </div>
            </form>
        </div>
</section></template>