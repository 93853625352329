import { addListnerForAuthStateVisibility, initFirebase } from "./utils/fbase"
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from "../App.vue"
import router from "./router"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useUserStore } from "./stores/user"

initFirebase()

const pinia = createPinia()
const vueApp = createApp(App)
vueApp.use(pinia)
vueApp.use(router)

const userStore = useUserStore()
onAuthStateChanged(getAuth(), (user) => {
    userStore.setIsAuthStateInitialised(true)
    if(user) {
        userStore.setUserObj(user)
        if(process.env.PRINT_ID_TOKEN_IN_CONSOLE === 'true') {

            user.getIdToken()
            .then(idToken => console.log(idToken))
            user.getIdTokenResult()
            .then(idTokenResult => {
                if(idTokenResult.claims.role && idTokenResult.claims.role === 'admin') userStore.isAccessDenied = false
            })
        }
    }
    else {
        if(router.currentRoute.value.meta.isProtected) router.push('/signin')
    }
})

document.addEventListener('DOMContentLoaded', () => {
    console.log("DOMContentLoaded")
    addListnerForAuthStateVisibility()
    
})


vueApp.mount('#app')