<script setup>
import { onMounted, ref } from 'vue';
import { getFunctions, httpsCallable } from "firebase/functions";
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { toast } from 'bulma-toast';


const upload_path = ref('')
const upload_files = ref([])
const s3Credentials = ref({})

onMounted(() => {
    const functions = getFunctions()
    const getCfR2Keys = httpsCallable(functions, 'getCfR2Keys')

    getCfR2Keys()
    .then(response => {
        const cfR2Credentials = response.data
        if (cfR2Credentials.hasOwnProperty('accessKeyId') && cfR2Credentials.hasOwnProperty('secretAccessKey')) {
            s3Credentials.value = cfR2Credentials
        }
        else {
            console.log("Can not find R2 credentials.")
        }
    })
})



async function handleStaticFileUpload(ev) {
    const form = ev.target

    const btn_submit_el = form['btn_submit']
    const upload_path_el = form['upload_path']
    const upload_path_custom_el = form['upload_path_custom']
    const upload_cache_control_el = form['upload_cache_control']
    const fileList = upload_files.value

    let path = upload_path_el.value
    if (path === 'custom-path/') path = upload_path_custom_el.value

    btn_submit_el.classList.add('is-loading')

    const s3Client = new S3Client({
        region: process.env.CF_R2_REGION,
        endpoint: process.env.CF_R2_ENDPOIN,
        credentials: {
            accessKeyId: s3Credentials.value.accessKeyId,
            secretAccessKey: s3Credentials.value.secretAccessKey,
        }
    })


    if (Object.keys(fileList).length) {

        let promises = []

        Object.keys(fileList).forEach(key => {
            const file = fileList[key]
            const fileName = file.name

            const objectKey = path + fileName

            try {
                promises.push(s3Client.send(new PutObjectCommand({
                    Bucket: 'static',
                    Key: objectKey,
                    Body: file,
                    ContentType: file.type,
                    CacheControl: upload_cache_control_el.value || "no-store"
                })))
            }
            catch(error) {
                console.log(error)
                toast({
                    message: "Some error occurred.",
                    type: 'is-danger'
                })
                btn_submit_el.classList.remove('is-loading')
            }

        })
        Promise.all(promises)
        .then(values => {
            toast({
                    message: "Success!",
                    type: 'is-success'
                })
        })
        .then(()=>{
            btn_submit_el.classList.remove('is-loading')
            form.reset()
        })
    }
    else {
        btn_submit_el.classList.remove('is-loading')
        // form.reset()
    }


}

</script>

<template>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
                <h1 class="title">
                    Upload Static Files
                </h1>
                <!-- <p class="subtitle">
                    Upload public files to the storage.
                </p> -->
            </div>
        </div>
    </section>
    <section class="section">
        <div class="container">
            <form action="/upload-static-files" method="post" id="form_static_files"
                @submit.prevent="handleStaticFileUpload($event)">
                <div class="field">
                    <label class="label">Select upload path</label>
                    <div class="control">
                        <div class="select">
                            <select id="upload_path" required v-model="upload_path">
                                <option value="www/">General</option>
                                <option value="product-images/small/">Product images (small)</option>
                                <option value="product-images/medium/">Product images (medium)</option>
                                <option value="product-images/large/">Product images (large)</option>
                                <option value="product-images/xlarge/">Product images (xlarge)</option>
                                <option value="catalogues/">Catalogues</option>
                                <option value="custom-path/">Custom path</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <label class="label">Custom path</label>
                    <div class="control">
                        <input id="upload_path_custom" class="input" placeholder="custom-path/"
                            pattern="[a-z0-9](?!.*[\-\/]{2})([a-z0-9\-\/]+)([\/])"
                            :required="upload_path === 'custom-path/'" :disabled="upload_path !== 'custom-path/'"
                            @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })">
                    </div>
                    <div class="help content mt-0" :class="{ 'is-hidden': upload_path !== 'custom-path/' }">
                        A valid path...
                    <ul>
                        <li>Only contain lowercase letters(a-z), numbers(0-9), hyphens(-), and forward slashes(/).</li>
                        <li>Must be 4-64 characters long.</li>
                        <li>Must start with a lowercase letter or a number.</li>
                        <li>Must not contain patterns: //, --, /-, -/</li>
                        <li>Must end with a forward slash(/).</li>
                    </ul>
                </div>
                </div>
                <div class="field">
                    <label class="label">Cache control header</label>
                    <div class="control">
                        <input id="upload_cache_control" class="input" placeholder="public, max-age=604800, s-maxage=3600" required
                            @invalid="$event.target.classList.add('is-danger'); $event.target.addEventListener('input', (ev) => { ev.target.classList.remove('is-danger'), { once: true } })">
                    </div>
                    <p class="help content mt-0">
                        <span class="tag is-warning">Attention!</span> It is very important that you specify correct <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Cache-Control" target="_blank" rel="noopener noreferrer">Cache-Control directives</a> for response.
                        <br>
                        Default is: <span class="has-text-dark">public, max-age=604800, s-maxage=3600</span>
                    </p>
                </div>

                <div class="field">
                    <div class="file has-name">
                        <label class="file-label">
                            <input id="upload_files" class="file-input" type="file" required multiple
                                @change="upload_files = $event.target.files">
                            <span class="file-cta">
                                <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                </span>
                                <span class="file-label">
                                    Select files...
                                </span>
                            </span>
                            <span class="file-name">
                                {{ upload_files.length }} Selected
                            </span>
                        </label>
                    </div>
                </div>

                <div class="field is-grouped">
                    <div class="control">
                        <button id="btn_submit" type="submit" class="button is-link">Upload</button>
                    </div>
                    <div class="control">
                        <button type="reset" class="button is-link is-light">Reset</button>
                    </div>
                </div>
            </form>
        </div>
    </section>
</template>