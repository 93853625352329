import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from 'firebase/auth'
import Home from "../routes/Home.vue"
import Signin from "../routes/Signin.vue"
import Catalogues from "../routes/Catalogues.vue"
import Products from "../routes/Products.vue"
import UploadStaticFiles from "../routes/UploadStaticFiles.vue"
import ChangeUserPassword from "../routes/ChangeUserPassword.vue"
import AssignUserRole from "../routes/AssignUserRole.vue"
import NotFound from "../routes/NotFound.vue"
import { useNavigationStore } from './stores/navigation'


const DEFAULT_TITLE = 'NFE Admin'

const router = createRouter({
    history: createWebHistory("/"),
    linkActiveClass: 'is-active',
    routes: [
        {
            path: '/',
            component: Home,
            meta: {
                isProtected: true
            }
        },
        {
            path: '/signin',
            component: Signin,
            meta: {
                isProtected: false,
                title: 'Sign in - NFE Admin'
            }
        },
        {
            path: '/catalogues',
            component: Catalogues,
            meta: {
                isProtected: true,
                title: 'Catalogues - NFE Admin'
            }
        },
        {
            path: '/products',
            component: Products,
            meta: {
                isProtected: true,
                title: 'Products - NFE Admin'
            }
        },
        {
            path: '/upload-static-files',
            component: UploadStaticFiles,
            meta: {
                isProtected: true,
                title: 'Upload Static Files - NFE Admin'
            }
        },
        {
            path: '/change-user-password',
            component: ChangeUserPassword,
            meta: {
                isProtected: true,
                title: 'Change User Password - NFE Admin'
            }
        },
        {
            path: '/assign-user-role',
            component: AssignUserRole,
            meta: {
                isProtected: true,
                title: 'Assign User Role - NFE Admin'
            }
        },
        {
            path: '/:pathMatch(.*)*',
            component: NotFound,
            meta: {
                isProtected: false,
                title: 'Page not found - NFE Admin'
            }
        }
    ]
})




router.beforeEach((to, from) => {
    document.title = to.meta.title || DEFAULT_TITLE
    if (!to.meta.isProtected || (getAuth().currentUser && to.path !== '/signin')) return true
    if (to.path !== '/signin') {
        const navigationStore = useNavigationStore()
        navigationStore.pathToRedirectAfterSignin = to.path
    }
    router.push('/signin')
})

export default router