import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
    const isAuthStateInitialised = ref(false)
    const userObj = ref(null)

    const isAccessDenied = ref(true)

    function setIsAuthStateInitialised(state) {
        isAuthStateInitialised.value = state
    }

    function setUserObj(user) {
        userObj.value = user
    }

    return { isAuthStateInitialised, userObj, isAccessDenied, setIsAuthStateInitialised, setUserObj }
})